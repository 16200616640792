import React from "react";
import { animateScroll } from 'react-scroll';
import 'twin.macro';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons';

library.add([faAngleUp]);

const PageTopAnchor = (props) => {
  return (
    <div tw="text-center" {...props}>
      <button tw="inline-block px-4 py-2 light:hover:bg-gray-200 dark:hover:bg-gray-800 rounded-full" onClick={animateScroll.scrollToTop}>
        <FontAwesomeIcon icon={["fal", "angle-up"]} />
      </button>
    </div>
  );
}

export default PageTopAnchor;
