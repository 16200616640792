import React, { useContext, useRef, useEffect, useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby";
import tw, { css } from 'twin.macro';

import { Link } from "gatsby";
import { ThemeContext } from '../contexts/themeContext';
import { SidebarContext } from '../contexts/sidebarContext';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSun, faMoon } from '@fortawesome/pro-light-svg-icons';

library.add([faBars, faTimes, faSun, faMoon]);

const SiteID = ({ isRootPath, children }) => {
  return (
    <>
      { isRootPath && <h1 tw="tracking-wide"><Link to="/" tw="text-xl font-bold">{children}</Link></h1> }
      { !isRootPath && <div tw="tracking-wide"><Link to="/" tw="text-xl font-bold">{children}</Link></div> }
    </>
  );
}

const GlobalHeader = ({ title, location }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  const { toggleTheme, isLight, isDark } = useContext(ThemeContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const data = useStaticQuery(graphql`
    query GlobalHeaderQuery {
      site {
        siteMetadata {
          navs {
            id
            name
            path
            partiallyActive
            target
          }
        }
      }
    }
  `);


  return (
    <>
      <header tw="fixed top-0 left-0 right-0 light:bg-gray-200 dark:bg-gray-800 light:bg-opacity-90 dark:bg-opacity-90 z-40" css={css` backdrop-filter: blur(8px); `} data-is-root-path={isRootPath}>
        <div tw="p-8 flex justify-between items-center space-x-4">
          <SiteID isRootPath={isRootPath}>{title}</SiteID>
          <nav tw="hidden md:block">
            <ul tw="flex items-center space-x-4">
              { data.site.siteMetadata.navs.map((item, index) => {
                return (
                  <li key={`nav_item_${index}`}>
                    <Link to={item.path} tw="inline-block px-2 py-2 text-base tracking-wider" css={NavItemCurrentStyle} partiallyActive={item.partiallyActive} activeClassName="current">{item.name}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <nav tw="flex items-center space-x-2">
            <button type="button" tw="px-2 py-2 w-10 text-sm" onClick={toggleTheme}>
              { isLight() && <FontAwesomeIcon icon={["fal", "sun"]} title="Change Dark" /> }
              { isDark() && <FontAwesomeIcon icon={["fal", "moon"]} title="Change Light" /> }
            </button>
            <button type="button" tw="md:hidden px-2 py-2 w-10 text-sm" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={["fal", "bars"]} title="Close" />
            </button>
          </nav>
        </div>
      </header>
      <Sidebar navs={data.site.siteMetadata.navs} />
    </>
  );
}

const Sidebar = ({ navs }) => {
  const { sidebarState, toggleSidebar, setSidebarState } = useContext(SidebarContext);
  const sidebarElement = useRef();

  const outSideClickHandler = useCallback((e) => {
    if(sidebarState && sidebarElement.current.contains(e.target)) {
      return;
    }

    setSidebarState(false);
  }, [sidebarElement, sidebarState]);

  useEffect(() => {
    document.addEventListener('mousedown', outSideClickHandler, false);
    document.addEventListener('touchstart', outSideClickHandler, false);

    return () => {
      document.removeEventListener('mousedown', outSideClickHandler, false);
      document.removeEventListener('touchstart', outSideClickHandler, false);
    }
  }, [sidebarElement, outSideClickHandler]);

  return (
    <div id="sidebar" css={SidebarStyle} tw="flex flex-col space-y-4 p-8 box-border light:bg-gray-100 dark:bg-gray-900 transition-transform z-50" data-is-sidebar={sidebarState} ref={sidebarElement}>
      <div tw="text-right">
        <button type="button" tw="px-2 py-2 w-10 text-lg light:text-gray-800 dark:text-gray-100" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={["fal", "times"]} title="Close" />
        </button>
      </div>
      <nav tw="flex flex-col flex-grow overflow-y-auto">
        <ul>
          { navs.map((item, index) => {
            return (
              <li key={`nav_item_${index}`}>
                <Link to={item.path} tw="block px-4 py-4 text-sm tracking-wider rounded-md dark:hover:bg-gray-800" css={NavItemCurrentStyle} partiallyActive={item.partiallyActive} activeClassName="current">{item.name}</Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

const SidebarStyle = css`
  ${tw`fixed top-0 right-0 bottom-0 shadow-none w-72 md:w-96 transform translate-x-72 md:translate-x-96`}

  &[data-is-sidebar="true"] {
    ${tw`shadow-2xl translate-x-0`}
  }
`;

const NavItemCurrentStyle = css`
  &.current {
    ${tw`font-bold`}
  }
`;

export default GlobalHeader;

