import React from "react";

import tw, {css, GlobalStyles } from 'twin.macro';
import { Global } from '@emotion/react';

import { ThemeProvider, ThemeConsumer } from '../contexts/themeContext';
import { SidebarProvider } from '../contexts/sidebarContext';

import GlobalHeader from '../components/header';
import GlobalContainer from '../components/container';
import GlobalFooter from '../components/footer';

const Layout = ({ location, title, children }) => {
  return (
    <ThemeProvider>
      <SidebarProvider>
        <ThemeConsumer>
          {({theme}) => (
            <div className={theme} tw="flex flex-col justify-between min-h-screen relative">
              <GlobalStyles />
              <Global
                styles={css`
                  body {
                    ${tw`leading-relaxed`}
                    ${theme === 'light' ? tw`bg-gray-200` : tw`bg-gray-800`}
                    ${theme === 'light' ? tw`text-gray-700` : tw`text-gray-300`}
                    font-family: "Noto Sans JP";
                  }
                `}
              />

              <GlobalHeader title={title} location={location} />
              <GlobalContainer>{children}</GlobalContainer>
              <GlobalFooter />
            </div>
          )}
        </ThemeConsumer>
      </SidebarProvider>
    </ThemeProvider>
  )
}

export default Layout;
