import React, { useState, createContext } from "react";

const SidebarContext = createContext();

const SidebarProvider = (props) => {
  const [sidebarState, setSidebarState] = useState(false);

  const toggleSidebar = (e) => {
    e.preventDefault();
    setSidebarState(!sidebarState);
  }

  const sidebarContext = {
    toggleSidebar,
    sidebarState,
    setSidebarState
  }

  return (
    <SidebarContext.Provider value={sidebarContext}>
      {props.children}
    </SidebarContext.Provider>
  );
}

const { Consumer } = SidebarContext;

export {
  SidebarContext,
  Consumer as SidebarConsumer,
  SidebarProvider
}
