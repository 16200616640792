import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import 'twin.macro';

import PageTopAnchor from './pageTopAnchor';

const GlobalFooter = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          navs {
            id
            name
            path
            partiallyActive
            target
          }
          copyright
        }
      }
    }
  `);

  return (
    <footer tw="px-8 py-12 light:bg-gray-300 dark:bg-gray-900" {...props}>
      <PageTopAnchor tw="mb-8" />
      <nav tw="mb-8">
        <ul tw="flex justify-center items-center flex-col sm:flex-row sm:space-x-4">
          { data.site.siteMetadata.navs.map((item, index) => {
            return (
              <li tw="list-none" key={`nav_item_${index}`}>
                <Link to={item.path} tw="block px-2 py-2 text-sm tracking-wider light:text-gray-600 dark:text-gray-400 hover:underline" partiallyActive={item.partiallyActive} activeClassName="current">{item.name}</Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div tw="text-center">
        <small tw="tracking-wide light:text-gray-500 dark:text-gray-500">
          © {new Date().getFullYear()} {data.site.siteMetadata.copyright}
        </small>
      </div>

    </footer>
  );
}

export default GlobalFooter;
