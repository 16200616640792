import React from "react";
import 'twin.macro';

const GlobalContainer = (props) => {
  return (
    <main tw="container mx-auto px-8 pt-32 pb-32" {...props} />
  );
}

export default GlobalContainer;
