import React, { useState, useEffect, createContext } from "react";

const Context = createContext({});

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }, []);

  const changeTheme = (theme) => {
    setTheme(theme);
    localStorage.theme = theme;
  }

  const toggleTheme = () => {
    changeTheme(theme === 'light' ? 'dark' : 'light');
  }

  const isLight = () => theme === 'light';
  const isDark  = () => theme === 'dark';

  const themeContext = {
    theme,
    changeTheme,
    toggleTheme,
    isLight,
    isDark
  }

  return (
    <Context.Provider value={themeContext}>{children}</Context.Provider>
  );
}

const { Consumer } = Context;

export {
  Context as ThemeContext,
  Consumer as ThemeConsumer,
  ThemeProvider
}
